const csrfTokenObj = () => {
  return { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
}
const authorizationObj = idToken => {
  return { 'Authorization': `Bearer ${idToken}` }
}
const xmlHttpRequest = () => {
  return { 'X-Requested-With': 'XMLHttpRequest' }
}

const railsLogin = (idToken) => {
  console.log('railsLogin')
  console.log('idToken', idToken)
  const axios = require('axios')
  const headers = Object.assign(csrfTokenObj(), authorizationObj(idToken), xmlHttpRequest())
  console.log(headers)
  axios({
    method: 'post',
    url: '/login',
    headers: headers,
    responseType: 'json',
  }).then(response => {
    console.log('response!!!')
    console.log(response.data)
    if (response.data.result !== 'success') {
      alert('ログインに失敗しました。しばらく待っても同じ動作の場合は、運営元にお問合せください。')
    }
    window.location.assign('/login')
  }).catch(error => {
    console.log('railsLoginError:', error)
  })
}

const firebaseLogin = () => {
  const ui = new firebaseui.auth.AuthUI(firebase.auth())
  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult, ridirectUrl) => {
        authResult.user.getIdToken(true)
          .then((idToken) => {
            railsLogin(idToken)
          })
          .catch((error) => {
            console.log(`Firebase getIdToken failed!: ${error.message}`)
          })
        return false
      },
      uiShown: () => {
        document.getElementById('loader').style.display = 'none'
      },
    },
    signInFlow: 'redirect',
    signInOptions: [
      // List of OAuth providers supported.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      'apple.com',
    ],
  }
  ui.start('#firebaseui-auth-container', uiConfig)
}

document.addEventListener('turbolinks:load', () => {
  firebaseLogin()
})
